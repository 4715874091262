import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import ScatImage from '../../Assets/scat-image.png';

const ScatAnalysis = () => {
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div>
          <img src={ScatImage} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
          Scat Analysis and Field Signs
          </h1>
          <p className="primary-text">
          Suggested projects under this topic include looking at the parasite loading of lion scats, collected
from the lion sanctuary within one of the reserves (lions were rescued from the canned hunting
trade and sterilised)
          </p>
          <p className="primary-text">
          Hyena prey studies, analysing hairs within scats to determine prey species of spotted or brown
hyena.
          </p>
          <p className="primary-text">
          Tracks – The man-made tracks that make up the dirt tracks on the reserve offer clear linear passage
through dense bush, wildlife often utilises these tracks during commuting. In previous projects
dirt/sand on the road has been smoothed out at sunset in pre-determined points, these can then be
checked the next morning to see which species has been active on the dirt tracks during the night.
Are they favoured by predator or prey species? Do we get more activity or different species
depending on the lunar cycle, bright night vs dark nights?
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ScatAnalysis