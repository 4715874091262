import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import VegetImage from '../../Assets/veget-image.png';

const Vegetation = () => {
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div>
          <img src={VegetImage} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
          Vegetation/Tree Damage
          </h1>
          <p className="primary-text">
          A large population of elephants is regularly seen within the Botswana reserve. Comparative studies
can be conducted to assess the changes in the landscape on the reserve with elephant presence vs
the reserve with elephants absent. Tree species changes, % of damaged/felled trees, number of new
trees growing, is the deadwood utilised and of benefit to other wildlife such as invertebrates. Studys
on this topic have used pre-determined transects to collect data from.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Vegetation