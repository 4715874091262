import React from 'react';
import Navbar from './Navbar';
import BannerBackground from '../Assets/home-banner-background.png';
import BannerBackground2 from '../Assets/home-banner-background_2.png';
import BannerImage from '../Assets/home-banner-image.png';
import { FiArrowRight } from 'react-icons/fi';
import About from './About';
import Work from './Work';
import Testimonial from './Testimonial';
import Contact from './Contact';
import Footer from './Footer';

const Home = () => {
  const handleEmailClick = () => {
    const emailAddress = 'info@vachelliaresearch.com';
    const emailSubject = 'Inquiry';
    const emailBody = 'Hi, I would like to get more information about...';
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

  // This will attempt to open the user's default email client in a new window/tab
  window.open(mailtoLink, '_blank');

  console.log('Email button clicked');
    
  };
  return (
    <div className='home-container'>
        <Navbar />
        <div className='home-banner-container'>
            <div className='home-bannerImage-container'>
                <img src={BannerBackground} alt='' />
            </div>
            <div className='home-text-section'>
                <h1 className='primary-heading'>
                Discover Africa’s wilderness through research projects, field trips and ecological placements
                </h1>
                <p className='primary-text'>
                Undertake your university research project/dissertation with us in either South Africa and/or Botswana.
                </p>
                <p className='primary-text'>
                Field trips, our two reserves situated either side of the Limpopo River give the perfect environment to base ecological and conservation focused field trips.
                </p>
                <button className='secondary-button' onClick={handleEmailClick}>
                    Email Us <FiArrowRight />
                </button>
            </div>
            <div className='home-image-container'>
                <img src={BannerImage} alt='' />
            </div>
        </div>
        <About />
        <Work />
        <Testimonial />
        {/* <Contact /> */}
        <Footer />
    </div>
  )
}

export default Home