import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import AnimalImage from '../../Assets/animal-image.png';


const AnimalsBehaviour = () => {
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div>
          <img src={AnimalImage} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
          Animals Behaviour
          </h1>
          <p className="primary-text">
          The lions within the sanctuary provide an opportunity for behaviour studies, this can relate to novel
objects, scents, personality, pride interaction or circadian rhythm. Bio acoustic projects can also be
conducted on the lion calls.
          </p>
          <p className="primary-text">
          Lunar cycle related behaviours such as increased/decreased movements during the night, predator
avoidance vs predator detection, do predators increase activity to utilise brighter nights for hunting?
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AnimalsBehaviour