import React, { useState} from 'react';
import Logo from '../Assets/Logo.svg';
import Logo2 from '../Assets/header-logo2.svg';
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FlightIcon from '@mui/icons-material/Flight';
import InfoIcon from "@mui/icons-material/Info";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
     navigate('/');
    };

    const handleEmailClick = () => {
        const emailAddress = 'info@vachelliaresearch.com';
      const emailSubject = 'Inquiry';
      const emailBody = 'Hi, I would like to get more information about...';
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    
      // This will attempt to open the user's default email client in a new window/tab
      window.open(mailtoLink, '_blank');
    
      console.log('Email button clicked');
        
      };
    

    const [openMenu, setopenMenu] = useState(false);
    const menuOptions = [
        {
          text: "Field Trips",
          icon: <FlightIcon />,
        },
        {
          text: "Research Projects",
          icon: <InfoIcon />,
        },
        {
          text: "Location",
          icon: <FmdGoodIcon />,
        },
        // {
        //   text: "Contact",
        //   icon: <PhoneRoundedIcon />,
        // },
        // {
        //   text: "Cart",
        //   icon: <ShoppingCartRoundedIcon />,
        // },
      ];

  return (
    <nav>
        {/* <div className='nav-logo-container'>
            <img src={Logo} alt="" />
        </div> */}
        <img src={Logo2} alt="Logo" className="logo-image" style={{ maxWidth: '350px' }} onClick={handleLogoClick} />

        
        <div className='navbar-links-container'>
            <a href='/fieldtrips'>Field Trips</a>
            <a href='/researchprojects'>Research Projects</a>
            <a href=''>Location</a>
            {/* <a href=''>
                <BsCart2 className='navbar-cart-icon' />
            </a> */}
            <button className='primary-button' onClick={handleEmailClick}>Email Us</button>
        </div>
        <div className='navbar-menu-container'>
            <HiOutlineBars3 onClick={() => setopenMenu(true)} />
        </div>
        <Drawer open={openMenu} onClose={() => setopenMenu(false)} anchor='right'>
            <Box
                sx={{ width: 250 }}
                role='presentation'
                onClick={() => setopenMenu(false)}
                onKeyDown={() => setopenMenu(false)}
            >
                <List>
                    {menuOptions.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

        </Drawer>
    </nav>
  )
}

export default Navbar