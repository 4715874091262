import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = '4407977430251'; // Your phone number with country code
  const defaultMessage = "I'm interested in your services.";

  const handleWhatsAppClick = () => {
    const chatUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(defaultMessage)}`;
    window.open(chatUrl, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleWhatsAppClick}>Chat with us on WhatsApp</button>
  );
};

export default WhatsAppButton;
