import React from 'react';
import { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import CameraBanner from '../../Assets/cameraBanner.png';
import Camera1 from '../../Assets/camera1.png';
import Camera2 from '../../Assets/camera2.png';
import Camera3 from '../../Assets/camera3.png';

const CameraTrapping = () => {
  const galleryImages = [
    { src: Camera1, alt: 'Description of Image 1' },
    { src: Camera2, alt: 'Description of Image 2' },
    { src: Camera3, alt: 'Description of Image 3' },
    // Add more images as needed
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const ImageModal = ({ image, onClose }) => {
    if (!image) return null;
  
    return (
      <div className="image-modal" onClick={onClose}>
        <img src={image.src} alt={image.alt} />
      </div>
    );
  };
    
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div className='research-image'>
          <img src={CameraBanner} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
            Camera trapping
          </h1>
          <p className="primary-text">
          Can be used as a standalone survey method or a survey aid to add context to other projects. Camera
trap studies can look at predator populations on the reserves projects to indicate individuals using
facial recognition, determine habitat preference, population dynamic, behaviour, impacts of
movements due to the changing lunar cycle.
          </p>
          <p className="primary-text">
          Herbivore projects can assist detection of long-term trends such as species decline, numbers of
species present to inform management plans for times of drought and habitat preference.
          </p>
          <p className="primary-text">
          Mesocarnivore studies will allow plans for further, detailed surveys. By knowing populations on the
reserve and habitat preference it will assist in future research projects such as satellite tracking.
          </p>
        </div>
      </div>
      <div className="image-gallery">
        {galleryImages.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} className="gallery-image" onClick={() => handleImageClick(image)} />
        ))}
      </div>
      <ImageModal image={selectedImage} onClose={() => setSelectedImage(null)} />

      <Footer />
    </div>
  )
}

export default CameraTrapping