import React from 'react';
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';



const About = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/fieldtrips');

    window.scrollTo(0, 0);
  };
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        {/* <p className="primary-subheading">Field Trips</p> */}
        <h1 className="primary-heading">
        Ecology and Conservation Field Trips in southern Africa
        </h1>
        <p className="primary-text">
        Our field trips involve hands on and relevant practical’s, based on courses and employment within
the field of professional ecology and wildlife conservation. The reserves we work on are based either
side of the Limpopo River in South Africa and Botswana which offer the perfect setting for field trips,
allowing a stay in either, or both countries during a visit.
        </p>
        <p className="primary-text">
        Tailored field trips with local site visits, course-specific activities, and a culminating quiz to reinforce the rich learning experience gained from Africa's diverse landscapes and wildlife.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button" onClick={handleNavigation}>Learn More</button>
          <a href="https://www.youtube.com/watch?v=puITGePWn-E" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button className="watch-video-button">
              <BsFillPlayCircleFill /> Watch Video
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default About