import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import FieldTrips from './Pages/FieldTrips';
import CameraTrapping from './Pages/ResearchProjects/CameraTrapping';
import AnimalsBehaviour from './Pages/ResearchProjects/AnimalsBehaviour';
import GIS from './Pages/ResearchProjects/GIS';
import ScatAnalysis from './Pages/ResearchProjects/ScatAnalysis';
import Telemetry from './Pages/ResearchProjects/Telemetry';
import Vegetation from './Pages/ResearchProjects/Vegetation';
import ResearchProjects from './Pages/ResearchProjects/ResearchProjects';


function App() {
  return (
    <BrowserRouter>
      <div className="App">     
      <Routes>
        <Route path="/" element={(<Home />)} />
        <Route path="/fieldtrips" element={(<FieldTrips />)} />
        <Route path="/cameratrapping" element={(<CameraTrapping />)} />
        <Route path="/animalsbehaviour" element={(<AnimalsBehaviour />)} />
        <Route path="/gis" element={(<GIS />)} />
        <Route path="/scatanalysis" element={(<ScatAnalysis />)} />
        <Route path="/telemetry" element={(<Telemetry />)} />
        <Route path="/vegetation" element={(<Vegetation />)} />
        <Route path="/researchprojects" element={(<ResearchProjects />)} />
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
