import React from 'react';
import Navbar from '../Components/Navbar';
import BannerBackground from '../Assets/home-banner-background.png';
import { FiArrowRight } from 'react-icons/fi';
import Footer from '../Components/Footer';
import FieldTripsImage from '../Assets/field-trip-image-final.png';
import Camera from '../Assets/camera.png';
import Analysis from '../Assets/analysis.png';
import Radio from '../Assets/radio.png';
import Bat from '../Assets/bat.png';
import Night from '../Assets/night.png';
import Game from '../Assets/game.png';
import Talk from '../Assets/talk.png';
import Quiz from '../Assets/quiz.png';


const FieldTrips = () => {
  const FieldTripsData = [
    {
      image: Camera,
      title: "Camera Trapping",
      text: "Capture elusive wildlife moments using discreet, motion-activated cameras in their natural habitats.",
    },
    {
      image: Radio,
      title: "Scat analysis",
      text: "Observe and document animal behaviors to understand ecological roles and social structures.",
    },
    {
      image: Analysis,
      title: "Field signs and tracking",
      text: "Investigate the ecological impact of elephant feeding habits on tree populations over time.",
    },
    {
        image: Bat,
        title: "Bat surveys",
        text: "Analyse lion scat for parasites to study health and disease dynamics in prides.",
      },
      {
        image: Night,
        title: "Infra-Red and thermal night vision aids",
        text: "Uncover dietary patterns and health indicators by examining various wildlife scat samples.",
      },
      {
        image: Game,
        title: "Game drives and night drives",
        text: "Explore species-specific habitat selections to aid in effective conservation planning.",
      },
      {
        image: Talk,
        title: "Talks from wildlife professionals",
        text: "Track animal movements using advanced telemetry to study migration, territory, and behaviors.",
      },
      {
        image: Quiz,
        title: "End of course quiz",
      },

  ];

  return (
    <div className='home-container'>
      <Navbar />
      <div className='home-banner-container'>
            <div className='home-bannerImage-container'>
                <img src={BannerBackground} alt='' />
            </div>
            <div className='home-text-section'>
                <h1 className='primary-heading'>
                Ecology and Conservation Field Trips in southern Africa
                </h1>
                <p className='primary-text'>
                Our field trips involve hands on and relevant practical’s, based on courses and employment within the field of professional ecology and wildlife conservation. The reserves we work on are based either side of the Limpopo River in South Africa and Botswana which offer the perfect setting for field trips, allowing a stay in either, or both countries during a visit.
                </p>
                <p className='primary-text'>
                Field trips are recommended for 2 weeks and can be tailored in advance to better suit your course contents to enhance the value and relevance of the trip to students.
                </p>
            </div>
            <div className='home-image-container'>
                <img src={FieldTripsImage} alt='' />
            </div>
      </div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
            <p className="primary-subheading">Field Trips</p>
            <h1 className="primary-heading">What We Offer</h1>
            <p className="primary-text">
            During the field trip we offer the following methods, which aim to give the student relevant transferrable skills which can be used when deciding a final year research project or within employment:
            </p>
        </div>
        <div className="work-section-bottom">
        {FieldTripsData.map((data) => (
            <div className="work-section-info" key={data.title}>
                <div className="info-boxes-img-container">
                    <img src={data.image} alt="" />
                </div>
                <h2>{data.title}</h2>
            </div>
        ))}
        </div>
    </div>
      <Footer />
    </div>
  )
}

export default FieldTrips