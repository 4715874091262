import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import GISImage from '../../Assets/gis-image.png';

const GIS = () => {
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div>
          <img src={GISImage} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
          GIS
          </h1>
          <p className="primary-text">
          Using a range of methods, including driving the dirt tracks and perimeter with GPS units and
inputting the data, marking water holes and surveying the reserves habitats using field work,
satellite imagery and drones, a map indicating different habitats within the reserve can be
comprised. This map can be used to highlight results found during other research projects or
potentially develop into a project on its own.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default GIS