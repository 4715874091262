import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import TeleImage from '../../Assets/tele-image.png';

const Telemetry = () => {
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='research-section-container'>
        <div>
          <img src={TeleImage} alt="" />
        </div>
        <div>
          <p className="primary-subheading">Research Projects</p>
          <h1 className="primary-heading">
          Telemetry and Satellite Tracking
          </h1>
          <p className="primary-text">
          Studies can be conducted to accurately determine habitat preference, how wildlife such as
elephants can cross borders and for any potential human/wildlife conflict may arise in agricultural
areas further along the Limpopo.
          </p>
          <p className="primary-text">
          To ensure we adhere to our ethical code, any collaring of wildlife would have to be for meaningful
long-term research which would benefit conservation, and be approved by the university involved.
          </p>
          <p className="primary-text">
          Tracking studies can yield extremely informative data and inform us of home ranges, distance
travelled, human proximity, border crossings, resting sites and activity vs a range of environmental
factors such as weather and temperature.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Telemetry