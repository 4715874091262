import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import WhatsAppButton from "./Wtsapp";

const Footer = () => {
    const handleEmailClick = () => {
        const emailAddress = 'info@vachelliaresearch.com';
        const emailSubject = 'Inquiry';
        const emailBody = 'Hi, I would like to get more information about...';
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
      
        // This will attempt to open the user's default email client in a new window/tab
        window.open(mailtoLink, '_blank');
      
        console.log('Email button clicked');
      };
      
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
        <div className="footer-section-columns">
          <span>+44 7977 430251</span>
          <span onClick={handleEmailClick}>info@vachelliaresearch.com</span>
        </div>
      </div>
      <div><WhatsAppButton /></div>
    </div>
  );
};

export default Footer;