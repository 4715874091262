import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Locations</p>
        <h1 className="primary-heading">South Africa</h1>
        <p className="primary-text">
        Makulu Makete is located in the northern part of the country, in the province of Limpopo, our
reserve is based in a quiet rural area, the nearest town is called Alldays and we are based to the
north west of it. The reserve contains a stretch of the Mogalakwena River and is a ten minute drive
to the Limpopo River.
        </p>
        <h1 className="primary-heading">Botswana</h1>
        <p className="primary-text">
        Situated on the Limpopo River and within the Tuli Block, an area known for its wildlife and diverse
landscapes. The reserve is host to a wide variety of wildlife which passes through, including
elephants, wild dogs, hyenas, lions, giraffe and a variety of antelope species and mesocarnivores.
The Limpopo River is home to hippo’s and crocodile and can be seen from the main lodge. The
Terrafou Reserve is approximately 30/40 minutes drive from the South Africa reserve.
        </p>
      </div>
    </div>
  );
};

export default Testimonial;