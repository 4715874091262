import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import BannerBackground from '../../Assets/home-banner-background.png';
import CameraT from '../../Assets/cameraT.png';
import Scat from '../../Assets/behaviour.png';
import GIS from '../../Assets/GIS.png';
import Animals from '../../Assets/animals.png';
import Tree from '../../Assets/tree.png';
import Satellite from '../../Assets/satellite.png';
import { useNavigate } from 'react-router-dom';
import FieldTripsImage from '../../Assets/field-trip-image.png';

const ResearchProjects = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

    const FieldTripsData = [
        {
          image: CameraT,
          title: "Camera trapping",
          text: "Capture elusive wildlife moments using discreet, motion-activated cameras in their natural habitats.",
          path: "/cameratrapping"
        },
        {
          image: Scat,
          title: "Scat Analysis and Field Signs",
          text: "Observe and document animal behaviors to understand ecological roles and social structures.",
          path: "/scatanalysis"
        },
        {
          image: GIS,
          title: "GIS",
          text: "Investigate the ecological impact of elephant feeding habits on tree populations over time.",
          path: "/gis"
        },
        {
            image: Animals,
            title: "Animals Behaviour",
            text: "Analyse lion scat for parasites to study health and disease dynamics in prides.",
            path: "/animalsbehaviour"
          },
          {
            image: Tree,
            title: "Vegetation/Tree Damage",
            text: "Uncover dietary patterns and health indicators by examining various wildlife scat samples.",
            path: "/vegetation"
          },
          {
            image: Satellite,
            title: "Telemetry and Satellite Tracking",
            text: "Explore species-specific habitat selections to aid in effective conservation planning.",
            path: "/telemetry"
          },
    
      ];
  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt='' />
      </div>
      <div className='home-banner-container'>
            <div className='home-text-section'>
                <h1 className='primary-heading'>
                  Research Projects
                </h1>
                <p className='primary-text'>
                The reserves offer a unique environment for students to conduct their research projects/dissertations in the African bush. Our camps are based within the wildlife reserves and offer a relaxing setting for students when not out collecting data, our camps offer:
                </p>
                <li className='primary-text'>
                  Work area to conduct write ups, data analysis
                </li>
                <li className='primary-text'>
                  WIFI to get online information or additional papers to research
                </li>
                <li className='primary-text'>
                  Swimming pool
                </li>
                <li className='primary-text'>
                  Kitchen area, equipped with microwave, cooking facilities, fridge
                </li>
                <li className='primary-text'>
                  Braai area
                </li>
                <li className='primary-text'>
                  Furnished accommodation: tents or rooms depending on location
                </li>
            </div>
            <div className='home-image-container'>
                <img src={FieldTripsImage} alt='' />
            </div>
      </div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
            <h1 className="primary-heading">What We Offer</h1>
            <p className="primary-text">
              We offer students a range of basic project themes that can be expanded on and adapted to come up with an individual thesis.
            </p>
            <p className="primary-text">
              Students are also welcome to come up with their own project ideas and submit a short methodology to ensure that it is achievable. We will assist with research permits if and when they are required.
            </p>
        </div>
        <div className="work-section-bottom">
        {FieldTripsData.map((data) => (
            <div className="work-section-info" key={data.title} onClick={() => handleNavigation(data.path)}>
                <div className="info-boxes-img-container">
                    <img src={data.image} alt="" />
                </div>
                <h2>{data.title}</h2>
            </div>
        ))}
        </div>
    </div>
      <Footer />
    </div>
  )
}

export default ResearchProjects